import { Box, Container, Divider, Grid } from '@mui/material'
import React from 'react'
import CardLogo from './CardLogo'
import PathPages from './PathPages'
import ContactData from './ContactData'
import Search from './Search'
import DownFooter from './DownFooter'

const Footer = () => {
    return (
        <div>
            <Box bgcolor={'#9383B0'} >
                <Container>
                    <Grid container spacing={2} sx={{ alignItems: 'center' }} >
                        <Grid item md={3} xs={12}>
                            <CardLogo />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <PathPages />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <ContactData />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Search />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Divider sx={{ backgroundColor: '#FFF', borderWidth: '1px' }} />
                            <DownFooter />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </div>
    )
}

export default Footer
