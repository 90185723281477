import { Box, Container, Typography } from '@mui/material'
import React from 'react'

const HeaderClients = () => {
    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', p: 3 }} >
                <Container>
                    <Box style={BoxHeader} >
                        <Typography color={'#9383B0'} sx={{ fontWeight: 'bold' }} >reviews</Typography>
                        <Typography variant='h4' sx={{ fontWeight: 'bold' }} >Clients Say</Typography>
                    </Box>
                </Container>
            </Box>
        </div>
    )
}
const BoxHeader = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
    width: '100%',
    margin: 'auto',
    textAlign: 'center'
}
export default HeaderClients
