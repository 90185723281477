import React from 'react'
import HeaderHowStart from './HeaderHowStart'
import ListStart from './ListStart'

const HowToStart = () => {
    return (
        <div style={howStart} id='how_to_start' >
            <HeaderHowStart />
            <ListStart />
        </div>
    )
}

const howStart = {
    padding: '50px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '18px'
}

export default HowToStart
