import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import CardPricing from './CardPricing'

const BoxPricing = () => {
    return (
        <div>
            <Container>
                <Box sx={{ boxShadow: ' 0px 4px 30px 0px rgba(0, 0, 0, 0.25)', p: 3 }} >
                    <Grid container spacing={2} >
                        <Grid item md={4} xs={12}>
                            <CardPricing
                                btnColor={'#7BB8D4'}
                                bgColor={'#7BB8D4'}
                                color={'#7BB8D4'}
                                date={'(30-minute class)'}
                                offer={'off 20%'}
                                price={'$10'}
                                supDate={'1 week'}
                                supPrice={'33$/ weekly'}
                                one={`-2 classes/ week
8 classes/ month ( $30 us)
`}
                                tow={`3 classes/ week
12 classes/ month ($45 us)
`}
                                three={`4 classes/ week
16 classes/ month ($60 us)

`}
                                four={`5 classes/ week 
20 classes/ month ($80 us) 
`}
                                five={`*Special offers for more than 5 classes/ week`}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CardPricing
                                btnColor={'#8F65DB'}
                                bgColor={'#8F65DB'}
                                color={'#8F65DB'}
                                date={'(45-minute class)'}
                                offer={'off 20%'}
                                price={'$10'}
                                supDate={'1 week'}
                                supPrice={'33$/ weekly'}
                                one={` One classes/ week 
4 classes/ month ( $32 us) `}
                                tow={`2 classes/ week 
8 classes/ month ($ 65 us) 
`}
                                three={`3 classes/ week
12 classes/ month ($90 us) 
`}
                                four={`
4 classes/ week 
16 classes/ month ($110 us)
`}
                                five={`5 classes / week 
20 classes/ month ($130 us) 
`}
                                six={`* Special offers for more than 5 classes/ week`}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CardPricing
                                btnColor={'#FCE51A'}
                                bgColor={'#FCE51A'}
                                color={'#FCE51A'}
                                date={'(60-minute class)'}
                                offer={'off 20%'}
                                price={'$10'}
                                supDate={'1 week'}
                                supPrice={'33$/ weekly'}
                                one={`One class/ week
4 classes/ month ($32 us) 

`}
                                tow={`2 classes/ week 
8 classes/ month ( $ 64 us) 

`}
                                three={`3 classes/ week 
12 classes/ month ($95 us) 


`}
                                four={`4 classes/ week 
16 classes/ month ($128us) 
`}
                                five={`5 classes/ week 
20 classes/ month ($160 us)
`}
                                six={`* Special offers for more than 5 classes/ week`}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Typography variant='h5' textAlign={'center'} >{`All packages are able to be shared among more than one person`}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}

export default BoxPricing
