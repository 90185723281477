import { Box } from '@mui/material'
import React from 'react'
import triller from './../../assets/triller/ايمان سكوول.mp4'
import './triller.css'
const Triller = () => {
    return (
        <div>
            <Box className='videoBg' sx={{ py: 2 }} >
                <video src={triller} loop width={'100%'} controls>
                    Your browser does not support the video tag.
                </video>
            </Box>
        </div>
    )
}

export default Triller
