import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import logo from './../../assets/logo/Logo.png'
import SotialFooter from './SotialFooter'
const CardLogo = () => {
    return (
        <div>
            <Box>
                <CardMedia
                    component="img"
                    image={logo}
                    title="green iguana"
                    sx={{
                        objectFit: "cover",
                        width: "fit-content",
                        margin: 'auto'
                    }}
                />
                {/* <Typography color={'white'} mb={1} >
                    Lorem ipsum dolor sit amet consectetur. Volutpat hac hac nisi elit purus. Nibh diam lacus ultrices arcu pharetra tortor ac.
                </Typography> */}
                <SotialFooter />
            </Box>
        </div>
    )
}
export default CardLogo
