import { Box, CardMedia, Container } from '@mui/material'
import React from 'react'
import header from './../../assets/header/cover size logo-01.png'
// import doted from './../../assets/header/doted.svg'
const Header = () => {
  return (
    <div>
      <Box sx={{ mt: { md: '10%', xs: '25%' } }} >
        <Container>
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'baseline', justifyContent: 'center' }} >
            <CardMedia
              sx={{
                objectFit: "fill",
                width: "100%",
              }}
              component="img"
              alt="green iguana"
              image={header}
            />
            {/* <CardMedia
              sx={{
                objectFit: "fill",
                height: '10vh',
                width: 'fit-content',
              }}
              component="img"
              alt="green iguana"
              image={doted}
            /> */}
          </Box>

        </Container>
      </Box>
    </div>
  )
}

export default Header
