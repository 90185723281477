import { Box, Button, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import left from './../../assets/aboutUs/left.png'
import right from './../../assets/aboutUs/right.png'
const AboutUs = () => {
    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center' }} >
                <Box sx={{ display: { md: 'inherit', xs: 'none' }, height: '200px', width: 'fit-content' }} >
                    <CardMedia
                        sx={{
                            objectFit: "fill",
                            width: "fit-content",
                            // height: "100vh",
                        }}
                        component="img"
                        alt="green iguana"
                        image={right}
                    />
                </Box>
                <Container>
                    <Box style={BoxAboutUs} sx={{ width: { md: '75%', xs: '100%' } }} >
                        <Typography color={'#9383B0'} sx={{ fontWeight: 'bold' }} >Iman school</Typography>
                        <Typography variant='h4' sx={{ fontWeight: 'bold' }} >About Us</Typography>
                        {/* <Typography sx={{ fontWeight: 400, color: '#737373' }} >Problems trying to resolve the conflict between
                            the two major realms of Classical physics: Newtonian mechanics </Typography> */}
                        <Typography sx={{ fontWeight: 'bold' }} >
                            Iman School is a leading online Islamic Home school that offers one of the most effective Arabic and online Quran teaching classes in addition to Islamic studies for both children and adults. We are experts in using state of the art technologies to teach online. With a lot of satisfied students all over the world, including all Arab countries ,USA, UK, Canada, UAE, Australia, France, and others. We have teacher’s who graduated from prestigious Islamic universities such as the Islamic University of Madinah, Al-Azhar University, and others.                            </Typography>
                        <a
                            href={'#Join_Us'}
                            style={{
                                color: "#FFF",
                                textDecoration: "none",
                                // fontSize: 18,
                                fontWeight: "bold",
                            }}>
                            <Button variant="contained" sx={{
                                backgroundColor: '#9383B0', ':hover': {
                                    backgroundColor: '#9383B0'
                                },
                                my: 1
                            }} className='box' endIcon={<ArrowRightAltIcon className='arrow' />}>
                                Enroll Now
                            </Button>
                        </a>
                    </Box>
                </Container>
                <Box sx={{ display: { md: 'inherit', xs: 'none' }, height: '200px', width: 'fit-content' }} >
                    <CardMedia
                        sx={{
                            objectFit: "fill",
                            width: "fit-content",
                            // height: "100vh",
                        }}
                        component="img"
                        alt="green iguana"
                        image={left}
                    />
                </Box>
            </Box>
        </div>
    )
}
const BoxAboutUs = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
    margin: 'auto',
    textAlign: 'center'
}
export default AboutUs
