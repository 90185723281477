import { Box, Button, Divider, Typography } from '@mui/material'
import React from 'react'
import ListPricing from './ListPricing'

const CardPricing = ({ date, supDate, offer, price, supPrice, color, bgColor, btnColor, one, tow, three, four, five, six }) => {
    return (
        <div>
            <Box position={'relative'} textAlign={'center'} sx={{ boxShadow: ' 0px 4px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: '20px' }} >
                <Box position={'relative'} >
                    <Box style={HeaderPricing} sx={{ backgroundColor: color, }} >
                        <Typography variant='h5' >{date}</Typography>
                        {/* <Typography>{supDate}</Typography> */}
                    </Box>
                    <Box style={cercleOffer} sx={{ background: bgColor, }} >
                        <Typography sx={{ fontSize: '15px' }} >{offer}</Typography>
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }} >{price}</Typography>
                        <Typography sx={{ fontSize: '13px' }}>{supPrice}</Typography>
                    </Box>
                </Box>
                <Box>
                    <ListPricing
                        one={one}
                        tow={tow}
                        three={three}
                        four={four}
                        five={five}
                        six={six}
                    />
                </Box>
                <Divider />
                <Button style={button} sx={{ color: btnColor }} >Choose The Package</Button>
            </Box>
        </div >
    )
}
const HeaderPricing = {
    padding: '40px 0',
    color: '#FFF',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px'
}
const cercleOffer = {
    margin: 'auto',
    color: '#FFF',
    borderRadius: '50%',
    height: '100px',
    width: '100px',
    display: 'flex',
    flexDirection: ' column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '4px solid #FFF',
    position: 'absolute',
    top: '75%',
    left: '50%',
    transform: 'translate(-50%, 0)',
    zIndex: 9999,
    fontWeight: 'bold',
    boxShadow: ' 0px 4px 30px 0px rgba(0, 0, 0, 0.25)'
}

const button = {
    width: '85%',
    padding: '20px 60px',
    margin: '15px auto',
    border: '2px solid',
    borderRadius: '45px',
}

export default CardPricing
