import React from 'react'
import HeaderClass from './HeaderClass'
import ListClasses from './ListClasses'
const OurClasses = () => {
    return (
        <div  >
            <HeaderClass />
            <ListClasses />
        </div>
    )
}

export default OurClasses
