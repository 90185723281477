import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import './cardStart.css'
const CardStart = ({ url, title, bgcolor }) => {
    return (
        <div>
            <Box style={BoxStart} sx={{ backgroundColor: bgcolor, height: { md: 'auto', xs: "175px" }, justifyContent: { md: 'center', xs: 'flex-start' }, }} >
                <Box style={boxImg} sx={{ padding: { md: '20px', xs: '10px' }, }} >
                    <CardMedia
                        sx={{
                            objectFit: "fill",
                            width: { md: "75px", xs: '30px' },
                            height: { md: "75px", xs: '30px' }
                        }}
                        component="img"
                        alt="green iguana"
                        image={url}
                        className='card-start'
                    />
                </Box>
                <Typography sx={{ fontSize: { md: '20px', xs: '14px' }, fontWeight: 'bold' }} >{title}</Typography>
            </Box>
        </div>
    )
}

const BoxStart = {
    borderRadius: '30px',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',

    color: '#FFF',
    boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.25)',
}

const boxImg = {

    backgroundColor: '#C5EFDA',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset',
    borderRadius: '50%',

}

export default CardStart
