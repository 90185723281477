import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import ContactData from './ContactData'
import Form from './Form'

const ContactUs = () => {
    return (
        <div id='Join_Us' >
            <Container>
                <Box >
                    <Grid container spacing={2} >
                        <Grid item md={8} xs={12}>
                            <Form />
                        </Grid>
                        <Grid item md={4} xs={12} sx={{ display: { md: 'block', xs: 'none' } }} >
                            <ContactData />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}

export default ContactUs
