
export const navLinks = [
    { href: "#Home", label: "Home" },
    { href: "#our_classes", label: "our classes" },
    { href: "#pricing", label: "pricing" },
    { href: "#Join_Us", label: "Join Us" },
];


export const footerLinks = [
    { name: "home", link: "#home" },
    { name: "how to start", link: "#how_to_start" },
    { name: "our classes", link: "#our_classes" },
    { name: "our packages", link: "#pricing" },
];

export const contact = [
    // { name: "+970592296600", link: "+970592296600" },
    { name: "WWW.IMANSCHOOL.COM", link: "WWW.IMANSCHOOL.COM" },
    { name: "info@iman-school.com", link: "info@iman-school.com" },
];