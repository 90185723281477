import { Box, Button, InputLabel, TextField } from '@mui/material'
import React, { useRef } from 'react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import emailjs from '@emailjs/browser';
import { enqueueSnackbar } from 'notistack';

const Form = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_njtgmyk', 'template_145i0z8', form.current, 'QoZguk_-vWKm5Gb6e')
            .then((result) => {
                console.log(result.text);
                enqueueSnackbar("Data sent successfully👍", 'success')
            }, (error) => {
                console.log(error.text);
                enqueueSnackbar(error, { variant: 'error' });
            });
        e.target.reset()
    }
    return (
        <div>
            <Box sx={{ boxShadow: ' 0px 4px 30px 0px rgba(0, 0, 0, 0.25)', p: 3, borderRadius: '30px' }}>
                <Box component={'form'} ref={form} onSubmit={sendEmail} >
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }} >
                        <Box sx={{ width: { md: '45%', xs: '100%' }, my: 1 }} >
                            <InputLabel style={label} htmlFor="name">
                                student full name:
                            </InputLabel>
                            <TextField
                                fullWidth
                                hiddenLabel
                                placeholder='enter your ful name'
                                id="name"
                                variant="filled"
                                size="medium"
                                name='name'
                            />
                        </Box>
                        <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}  >
                            <InputLabel style={label} htmlFor="phone">
                                phone number:
                            </InputLabel>
                            <TextField
                                fullWidth
                                hiddenLabel
                                placeholder='enter your phone number'
                                id="phone"
                                variant="filled"
                                size="medium"
                                name='phone'
                            />
                        </Box>
                        <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}  >
                            <InputLabel style={label} htmlFor="email">
                                email address:
                            </InputLabel>
                            <TextField
                                fullWidth
                                hiddenLabel
                                placeholder='enter your email'
                                id="email"
                                variant="filled"
                                size="medium"
                                name='email'
                            />
                        </Box>
                        <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}  >
                            <InputLabel style={label} htmlFor="country">
                                your country:
                            </InputLabel>
                            <TextField
                                fullWidth
                                hiddenLabel
                                placeholder='choose your country'
                                id="country"
                                variant="filled"
                                size="medium"
                                name='country'
                            />
                        </Box>
                        <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}  >
                            <InputLabel style={label} htmlFor="number_2">
                                second number:
                            </InputLabel>
                            <TextField
                                fullWidth
                                hiddenLabel
                                placeholder='enter your secondary numper'
                                id="number_2"
                                variant="filled"
                                size="medium"
                                name='number_2'
                            />
                        </Box>
                        <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}  >
                            <InputLabel style={label} htmlFor="city">
                                your city:
                            </InputLabel>
                            <TextField
                                fullWidth
                                hiddenLabel
                                placeholder='enter your city'
                                id="city"
                                variant="filled"
                                size="medium"
                                name='city'
                            />
                        </Box>
                    </Box>
                    <Box textAlign={'center'} >
                        <Button variant="contained"
                            type="submit"
                            sx={{
                                backgroundColor: '#9383B0', ':hover': {
                                    backgroundColor: '#9383B0'
                                },
                                margin: 3
                            }} className='box' endIcon={<ArrowRightAltIcon className='arrow' />}>Enroll now</Button>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}
const label = {
    color: '#9383B0', fontWeight: 'bold', margin: '8px 0'
}
export default Form
