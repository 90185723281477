import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import CardReviews from './CardReviews'
import Carousel from 'react-material-ui-carousel'
const ListReviews = () => {
    return (
        <div>
            <Carousel
                height={350}
                sx={{
                    width: { md: "75%", xs: "100%" },
                    margin: "auto",
                    display: { md: 'none', xs: 'inherit' }
                }}>
                <CardReviews
                    name={`Abdullah Gh`}
                    supText={`My three children have been in Al-Iman School for six months. They started with Sheikh Mohammed, who is a very good teacher, and they love him very much. Thank you.
`}
                />
                <CardReviews
                    name={`Naila R`}
                    supText={`I would like to thank you all, especially  teacher Iman. She  made my little daughter very proud of herself. She began to read the Arabic language and the Qur’an in tajweed on her own and write them well. This is more than I had hoped for. jazakumullah khairan for everything.
`}
                />
                <CardReviews
                    name={`Um Aminah`}
                    supText={`jazak Allh khair for continuing this program through highly qualified teachers. I tried two teachers( Sarah & Iman), and they are the best teachers I have seen. We have been with them for a long time, and I hope my children will always continue with them.
`}
                />
                <CardReviews
                    name={`Manal um usuf`}
                    supText={`Alikumu Assalam
yes sure, my son really likes his teacher she is funny and very good in teaching the kids especially those who like my son,  he appreciate her and I feel like she treats him as me as his mum , thank you guys
`}
                />
            </Carousel>
            <Container sx={{ display: { md: 'inherit', xs: 'none' } }} >
                <Box >
                    <Grid container spacing={2} >
                        <Grid item md={6} xs={12}>
                            <CardReviews
                                name={`Abdullah Gh`}
                                supText={`My three children have been in Al-Iman School for six months. They started with Sheikh Mohammed, who is a very good teacher, and they love him very much. Thank you.
`}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CardReviews
                                name={`Naila R`}
                                supText={`I would like to thank you all, especially  teacher Iman. She  made my little daughter very proud of herself. She began to read the Arabic language and the Qur’an in tajweed on her own and write them well. This is more than I had hoped for. jazakumullah khairan for everything.
`}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CardReviews
                                name={`Um Aminah`}
                                supText={`jazak Allh khair for continuing this program through highly qualified teachers. I tried two teachers( Sarah & Iman), and they are the best teachers I have seen. We have been with them for a long time, and I hope my children will always continue with them.
`}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CardReviews
                                name={`Manal um usuf`}
                                supText={`Alikumu Assalam
 yes sure, my son really likes his teacher she is funny and very good in teaching the kids especially those who like my son,  he appreciate her and I feel like she treats him as me as his mum , thank you guys
`}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}

export default ListReviews
