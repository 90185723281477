import React from 'react'
import { Box, CardMedia, Container, Typography } from '@mui/material'
import left from './../../assets/aboutUs/left.png'
import right from './../../assets/aboutUs/right.png'
const HeaderPricing = () => {
    return (
        <div id='pricing' style={{ padding: '30px 0' }} >
            <Box sx={{ display: 'flex', alignItems: 'center' }} >
                <Box sx={{ display: { md: 'inherit', xs: 'none' }, height: '200px', width: 'fit-content' }}>
                    <CardMedia
                        sx={{
                            objectFit: "fill",
                            width: "fit-content",
                            // height: "100vh",
                        }}
                        component="img"
                        alt="green iguana"
                        image={right}
                    />
                </Box>
                <Container>
                    <Box style={BoxHeader} >
                        <Typography color={'#9383B0'} sx={{ fontWeight: 'bold' }} >Payment</Typography>
                        <Typography variant='h4' sx={{ fontWeight: 'bold' }} >Pricing</Typography>
                        {/* <Typography sx={{ fontWeight: 'bold' }} >
                            Due to the high prices of Islamic actual schools, we offer you different price packages that suit everyone and are determined according to the number of weekly                        </Typography> */}
                    </Box>
                </Container>
                <Box sx={{ display: { md: 'inherit', xs: 'none' }, height: '200px', width: 'fit-content' }}>
                    <CardMedia
                        sx={{
                            objectFit: "fill",
                            width: "fit-content",
                        }}
                        component="img"
                        alt="green iguana"
                        image={left}
                    />
                </Box>
            </Box>
        </div>
    )
}
const BoxHeader = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
    width: '75%',
    margin: 'auto',
    textAlign: 'center'
}
export default HeaderPricing
