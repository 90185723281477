import './App.css';
import ClientsSay from './componants/Clients Say/ClientsSay';
import ContactUs from './componants/Contact/ContactUs';
import Footer from './componants/Footer/Footer';
import Header from './componants/Header/Header';
import NavBar from './componants/NavBar';
import Triller from './componants/Triller/Triller';
import AboutUs from './componants/about us/AboutUs';
import HowToStart from './componants/how to start/HowToStart';
// import ImbactNumper from './componants/impactNumper/ImbactNumper';
import OurClasses from './componants/our classes/OurClasses';
import Pricing from './componants/pricing/Pricing';
import SotialFooter from './componants/glopal/SotialFooter';
import ScrollTo from './componants/Ui/ScrollToTop';
function App() {
  return (
    <div className="App">
      <NavBar />
      <ScrollTo />
      <SotialFooter />
      <Header />
      <Triller />
      <AboutUs />
      {/* <ImbactNumper /> */}
      <OurClasses />
      <Pricing />
      <HowToStart />
      <ContactUs />
      <ClientsSay />
      <Footer />
    </div>
  );
}

export default App;
