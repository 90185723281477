import React from 'react'
import HeaderClients from './HeaderClients'
import ListReviews from './ListReviews'
import ContactData from '../Contact/ContactData'
import { Box } from '@mui/material'

const ClientsSay = () => {
    return (
        <div style={{ padding: '50px 0' }} >
            <HeaderClients />
            <ListReviews />
            <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                <ContactData />
            </Box>
        </div>
    )
}

export default ClientsSay
