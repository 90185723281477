import { Container, Grid } from '@mui/material'
import React from 'react'
import CardClass from './CardClass'
import c1 from './../../assets/CardClass/c1.jpg'
import c2 from './../../assets/CardClass/c2.jpg'
import c3 from './../../assets/CardClass/c3.jpg'
import c4 from './../../assets/CardClass/c4.jpg'
const ListClasses = () => {
    return (
        <div>
            <Container>
                <Grid container spacing={2} >
                    <Grid item md={3} xs={12}>
                        <CardClass url={c4} title={'Foundation course'}
                            description={'This course is best for all beginners both adults and children, as it provides a fun and exciting atmosphere to learn Qur’an. Suitable for children ages 5 and up, students will learn to recite with accuracy and preciseness                         Part of each lesson focuses on Tajweed to ensure that proper pronunciation is met while memorizing Get your children started with the proven Noorani Qaida methodology to learn how to read Arabic and recite the Holy Quran confidently in a short period of time.'} />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <CardClass url={c3} title={'Quraan Mimrization and recitation'}
                            description={'We offer Qur’an memorization classes in the classroom in different ways for all levels, through memorization and repetition, as well as recitation classes where the student reads and the teacher listens to him and corrects his mistakes with a simplified explanation of the meanings of the verses, all of this with an explanation of Tajweed.'} />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <CardClass url={c2} title={'Islamic stadies'}
                            description={`One of the most important features of this course is that it is divided into levels appropriate to different ages. From 7 years of age, the student can learn the Islamic sciences that suit his age and mind (such as the pillars of Islam, stories of the prophets, the basics of purity, and a few supplications) 
There are also studies of (jurisprudence, hadith, interpretation, and monotheism, and this is more suitable for adults`} />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <CardClass url={c1} title={'Arabic language'}
                            description={'Learning the Arabic language with all its skills (writing and advanced reading such as stories, hadiths, Islamic biography, etc., listening and training in speaking Arabic), which will make you able to understand the meanings of the Qur’an, Islamic sciences, etc.'} />
                    </Grid>

                </Grid>
            </Container>
        </div>
    )
}

export default ListClasses
