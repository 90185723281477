import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'

const SotialCard = ({ url, text }) => {
    return (
        <div>
            <Box sx={{ display: 'flex' }} >
                <CardMedia
                    sx={{
                        objectFit: "fill",
                        width: "fit-content",
                        // height: "100vh",
                    }}
                    component="img"
                    alt="green iguana"
                    image={url}
                />
                <Typography>{text}</Typography>
            </Box>
        </div>
    )
}

export default SotialCard
