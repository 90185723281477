import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Button, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavDrawer from "./NavDrawer";
// import Logo from './../../assets/logo/Logo.png'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { navLinks } from "../../constants";
function NavBar() {
    const [drawer, setDrawer] = useState(false);
    return (
        <>
            <AppBar position="static" elevation={0}
                style={StyleAppBar} >
                <Container>
                    <Toolbar disableGutters sx={{ display: 'flex', alignItems: 'center', }} >
                        {/* <a
                            href="/"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            <CardMedia
                                sx={{
                                    height: '75px',
                                    width: "170px",
                                    objectFit: "cover",
                                }}
                                component="img"
                                alt="green iguana"
                                image={Logo}
                            />
                        </a> */}
                        <Box
                            sx={{
                                flexGrow: 1,
                                marginInlineStart: { md: 8, xs: 0 },
                                gap: { md: 5, xs: 1 },
                                display: { md: "flex", xs: "flex" },
                                alignItems: "center"
                            }}>
                            {
                                navLinks?.map((link) => (
                                    <a
                                        href={link.href}
                                        style={{
                                            color: "inherit",
                                            textDecoration: "none",
                                            fontSize: 18,
                                            fontWeight: "bold",
                                        }}>
                                        {link.label}
                                    </a>)
                                )}
                        </Box>
                        <Box sx={{ display: { md: 'inherit', xs: 'none' } }} >
                            <a
                                href="https://wa.me/c/201024131839"
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    color: "#FFF",
                                    textDecoration: "none",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                }}>
                                <Button variant="contained" startIcon={<WhatsAppIcon />}
                                    sx={{
                                        backgroundColor: '#45C655', color: '#FFF', gap: 2, fontSize: '1rem', ":hover": {
                                            backgroundColor: "#45c655",
                                        }
                                    }}>
                                    Whats App
                                </Button>
                            </a>
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,

                                display: { md: "none", xs: "none" },
                                justifyContent: "end",
                            }}
                        >
                            <IconButton onClick={() => setDrawer(true)}>
                                <MenuIcon />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <NavDrawer drawer={drawer} setDrawer={setDrawer} />
        </>
    );
}
const StyleAppBar = {
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    backgroundColor: "rgba(147, 131, 176, 0.40)",
    color: '#000',
    position: "absolute",
    left: 0,
    top: 0,
}
export default NavBar;
