import { Box, Typography } from '@mui/material'
import React from 'react'
import { footerLinks } from '../../constants'
const PathPages = () => {
    return (
        <div>
            <Box>
                <Typography sx={{ fontWeight: 'bold' }} variant='h6' >sections</Typography>
                <Box
                    sx={{

                        gap: 2,
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "left",
                        padding: "20px",
                    }}>
                    {
                        footerLinks?.map((link) => (
                            <a
                                href={link.link}
                                style={{
                                    color: "#FFF",
                                    textDecoration: "none",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                }}>
                                -{link.name}
                            </a>))}
                </Box>
            </Box>
        </div>
    )
}

export default PathPages
