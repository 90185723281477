import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import CardStart from './CardStart'
import one from './../../assets/howToStart/one.png'
import tow from './../../assets/howToStart/tow.png'
import three from './../../assets/howToStart/three.png'
const ListStart = () => {
    return (
        <div>
            <Container>
                <Box >
                    <Grid container spacing={{ md: 2, xs: 1 }} >
                        <Grid item md={4} xs={4}>
                            <CardStart url={one} title={'Choose your course '} bgcolor={'#7BB8D4'} />
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <CardStart url={tow} title={'Fill out the form '} bgcolor={'#8F65DB'} />
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <CardStart url={three} title={'attend your first trial class'} bgcolor={'#FCE51A'} />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}

export default ListStart
