import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import LanguageIcon from '@mui/icons-material/Language';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailIcon from '@mui/icons-material/Email';
import SothialContact from './SothialContact';

const ContactData = () => {
    return (
        <div>
            <Box style={boxData} sx={{ height: { md: '70vh', xs: '50vh' }, }} >
                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, flexDirection: 'column' }} >
                    <Typography variant='h4' >contact us</Typography>
                    <Typography variant='h5' >connect information</Typography>
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={2} >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                        <LanguageIcon sx={{ fontSize: '1.5rem' }} />
                        <Typography >WWW.IMANSCHOOL.COM</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                        <LocalPhoneOutlinedIcon sx={{ fontSize: '1.5rem' }} />
                        <a href="tel:+201024131839" style={{ color: '#FFF', fontWeight: 'bold', textDecoration: 'none' }} ><Typography >+201024131839</Typography></a>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                        <EmailIcon sx={{ fontSize: '1.5rem' }} />
                        <a href="mailto:info@iman-school.com" style={{ color: '#FFF', fontWeight: 'bold', textDecoration: 'none' }}>
                            <Typography >info@iman-school.com</Typography>
                        </a>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: { md: '20%', xs: 0 } }} >
                    <SothialContact />
                    <Avatar style={avatar} />
                </Box>
            </Box>
        </div>
    )
}
const boxData = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#9383B0',
    padding: '24px',
    color: '#FFF',

    alignItems: 'flex-start',
    justifyContent: 'space-around',
    position: 'relative',
    borderRadius: '30px'

}

const avatar = {
    height: '100px',
    width: '100px',
    background: 'rgba(255, 255, 255, 0.50)',
    color: 'transparent'
}
export default ContactData
