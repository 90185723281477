import { Box, Typography } from '@mui/material'
import React from 'react'
import { contact } from '../../constants'

const ContactData = () => {
    return (
        <div>
            <Box>
                <Typography sx={{ fontWeight: 'bold' }} variant='h6' >contact</Typography>
                <Box
                    sx={{

                        gap: 2,
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "left",
                        padding: "20px",
                    }}>
                    <a
                        href="tel:+201024131839"
                        style={{
                            color: "#FFF",
                            textDecoration: "none",
                            fontSize: 15,
                            fontWeight: "bold",
                        }}>
                        <Typography >-+201024131839</Typography>
                    </a>
                    {
                        contact?.map((link) => (
                            <a
                                href={link.link}
                                style={{
                                    color: "#FFF",
                                    textDecoration: "none",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                }}>
                                -{link.name}
                            </a>)
                        )}
                </Box>
            </Box>
        </div>
    )
}

export default ContactData
