import { Box, Container, Typography } from '@mui/material'
import React from 'react'

const HeaderHowStart = () => {
    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center' }} >
                <Container>
                    <Box style={BoxHeader} sx={{ width: { md: '75%', xs: '100%' } }} >
                        <Typography color={'#9383B0'} sx={{ fontWeight: 'bold' }} >Get Started</Typography>
                        <Typography variant='h4' sx={{ fontWeight: 'bold' }} >how to start?</Typography>
                        {/* <Typography sx={{ fontWeight: 'bold' }} >
                            Lorem ipsum dolor sit amet consectetur. Malesuada elementum hendrerit risus ipsum adipiscing enim enim placerat. Fermentum faucibus amet feugiat eget non quam quis vel. Tempus nulla sit vitae ac tempor orci eu in sit. Etiam egestas tristique nulla eu in sit tortor nisi. Nisl ut aliquam nulla cras viverra vestibulum aliquam massa. Integer sagittis condimentum leo tortor mauris. Magna.
                        </Typography> */}
                    </Box>
                </Container>
            </Box >
        </div >
    )
}
const BoxHeader = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
    margin: 'auto',
    textAlign: 'center'
}
export default HeaderHowStart
