import React from 'react'
import HeaderPricing from './HeaderPricing'
import { Box, Typography } from '@mui/material'
import BoxPricing from './BoxPricing'

const Pricing = () => {
    return (
        <div>
            <HeaderPricing />
            <Box sx={{ display: { md: 'flex', xs: 'none' }, alignItems: 'center', justifyContent: 'space-evenly' }} >
                <Box style={BoxPrice} >
                    <Typography style={Numper} sx={{ backgroundColor: '#7BB8D4' }} >60</Typography>
                    <Typography>60 minutes</Typography>
                </Box>
                <Box style={BoxPrice} >
                    <Typography style={Numper} sx={{ backgroundColor: '#5aaa81' }}>40</Typography>
                    <Typography>40 minutes</Typography>
                </Box>
                <Box style={BoxPrice} >
                    <Typography style={Numper} sx={{ backgroundColor: '#FCE51A' }}>30</Typography>
                    <Typography>30 minutes</Typography>
                </Box>
            </Box>
            <BoxPricing />
        </div>
    )
}
const BoxPrice = {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    padding: '5px 20px',
    backgroundColor: '#FFF',
    boxShadow: ' 0px 4px 30px 0px rgba(0, 0, 0, 0.25)'
}
const Numper = {
    display: 'flex',
    padding: 10,
    borderRadius: '16px',
    width: 'fit-content',
    color: '#fff'

}
export default Pricing
