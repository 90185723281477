import React from 'react'
import { Box, CardMedia, Container, Typography } from '@mui/material'
import left from './../../assets/aboutUs/left.png'
import right from './../../assets/aboutUs/right.png'
const HeaderClass = () => {
    return (
        <div id='our_classes' style={{ padding: '30px 0' }} >
            <Box sx={{ display: 'flex', alignItems: 'center' }} >
                <Box sx={{ display: { md: 'inherit', xs: 'none' }, height: '200px', width: 'fit-content' }} >
                    <CardMedia
                        sx={{
                            objectFit: "fill",
                            width: "fit-content",
                            // height: "100vh",
                        }}
                        component="img"
                        alt="green iguana"
                        image={right}
                    />
                </Box>
                <Container>
                    <Box style={BoxHeader} sx={{ width: { md: '75%', xs: '100%' }, }} >
                        <Typography color={'#9383B0'} sx={{ fontWeight: 'bold' }} >Education</Typography>
                        <Typography variant='h4' sx={{ fontWeight: 'bold' }} >Our Courses</Typography>
                        {/* <Typography sx={{ fontWeight: 'bold' }} >
                            The Foundation Course
                            This course is best for all beginners both adults and children, as it provides a fun and exciting atmosphere to learn Qur’an.

                            Suitable for children ages 5 and up, students will learn to recite with accuracy and preciseness.

                            Part of each lesson focuses on Tajweed to ensure that proper pronunciation is met while memorizing.

                            Get your children started with the proven Noorani Qaida methodology to learn how to read Arabic and recite the Holy Quran confidently in a short period of time.                        </Typography> */}
                    </Box>
                </Container>
                <Box sx={{ display: { md: 'inherit', xs: 'none' }, height: '200px', width: 'fit-content' }} >
                    <CardMedia
                        sx={{
                            objectFit: "fill",
                            width: "fit-content",
                        }}
                        component="img"
                        alt="green iguana"
                        image={left}
                    />
                </Box>
            </Box>
        </div>
    )
}
const BoxHeader = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
    margin: 'auto',
    textAlign: 'center'
}
export default HeaderClass
