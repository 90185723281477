import { Avatar, Box, Rating, Typography } from '@mui/material'
import React from 'react'

const CardReviews = ({ name, supText }) => {
    return (
        <div>
            <Box style={boxCard} sx={{ height: { md: 'auto', xs: '300px' } }} >
                <Box style={flexBox} >
                    <Box>
                        <Avatar />
                    </Box>
                    <Box>
                        <Typography sx={{ fontWeight: 'bold', color: '#9383B0' }} >{name}</Typography>
                        <Typography>
                            {supText}
                        </Typography>
                        <Rating name="read-only" value={5} readOnly sx={{ color: '#9383b0' }} />
                    </Box>
                </Box>
            </Box>
        </div>
    )
}
const boxCard = {
    border: '2px solid #9383B0',
    padding: '20px',
    borderRadius: '30px',
    // height: '300px'
}

const flexBox = {
    display: 'flex',
    gap: '15px'

}
// const avatar = {
//     height: '100px',
//     width: '100px',
//     background: 'rgba(255, 255, 255, 0.50)',
//     color: 'transparent'
// }
export default CardReviews
