import { Box } from '@mui/material'
import React from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
const SotialFooter = () => {
    return (
        <div>
            <Box style={social}>
                <Box style={BoxSocial} >
                    <a href="https://wa.me/c/201024131839"
                        target="_blank" rel="noopener noreferrer">
                        <WhatsAppIcon sx={{ color: '#9383B0', fontSize: '1.5rem' }} />
                    </a>
                </Box>
                <Box style={BoxSocial} >
                    <InstagramIcon sx={{ color: '#9383B0', fontSize: '1.5rem' }} />
                </Box>
                <Box style={BoxSocial} >
                    <a href="https://www.facebook.com/profile.php?id=100092558956615&mibextid=ZbWKwL"
                        target="_blank" rel="noopener noreferrer">
                        <FacebookIcon sx={{ color: '#9383B0', fontSize: '1.5rem' }} />
                    </a>
                </Box>
                <Box style={BoxSocial} >
                    <TwitterIcon sx={{ color: '#9383B0', fontSize: '1.5rem' }} />
                </Box>
            </Box>
        </div>
    )
}

const BoxSocial = {
    padding: '8px',
    backgroundColor: '#FFF',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',

}
const social = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'space-around',
}
export default SotialFooter
