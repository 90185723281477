import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Box, Button } from "@mui/material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function NavDrawer({ setDrawer, drawer }) {
    return (
        <div>
            <React.Fragment>
                <Drawer
                    anchor="left"
                    open={drawer}
                    onClose={() => setDrawer(false)}
                >
                    <Box
                        sx={{
                            width: 300,
                            gap: 3,
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "left",
                            padding: "40px 20px",
                        }}
                    >
                        <a
                            href="/"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            Home
                        </a>
                        <a
                            href="/"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            our classes
                        </a>
                        <a
                            href="/contact"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            pricing
                        </a>

                        <a
                            href="/service"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            Join Us
                        </a>
                        <a
                            href="https://whas.me/o0uop6jdaX"
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                color: "#FFF",
                                textDecoration: "none",
                                fontSize: 18,
                                fontWeight: "bold",
                            }}>
                            <Button variant="contained" startIcon={<WhatsAppIcon />}
                                sx={{
                                    backgroundColor: '#45C655', color: '#FFF', gap: 2, fontSize: '1rem', ":hover": {
                                        backgroundColor: "#45c655",
                                    }
                                }}>
                                Whats App
                            </Button>
                        </a>
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
}
