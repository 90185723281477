import { Box, InputAdornment, InputLabel, TextField } from '@mui/material'
import React from 'react'
import TelegramIcon from '@mui/icons-material/Telegram';
const Search = () => {
    return (
        <div>
            <Box>
                {/* <Typography>get the latest news</Typography> */}
                <Box sx={{ my: 2 }}>
                    <InputLabel style={label} htmlFor="emailFoot">
                        get the latest news :
                    </InputLabel>
                    <TextField
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <TelegramIcon />
                                </InputAdornment>
                            )
                        }}
                        fullWidth
                        hiddenLabel
                        placeholder='enter your email'
                        id="emailFoot"
                        variant="filled"
                        size="medium"
                        sx={{ background: '#FFF' }}
                    />
                </Box>
            </Box>
        </div>
    )
}
const label = {
    color: '#000', fontWeight: 'bold', margin: '8px 0'
}
export default Search
