import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
const ListPricing = ({ one, tow, three, four, five, six }) => {
    return (
        <div>
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', mt: '60px' }}
                aria-label="contacts"
            >
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <CheckIcon style={chick} />
                        </ListItemIcon>
                        <ListItemText primary={one} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <CheckIcon style={chick} />
                        </ListItemIcon>
                        <ListItemText primary={tow} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <CheckIcon style={chick} />
                        </ListItemIcon>
                        <ListItemText primary={three} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <CheckIcon style={chick} />
                        </ListItemIcon>
                        <ListItemText primary={four} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <CheckIcon style={chick} />
                        </ListItemIcon>
                        <ListItemText primary={five} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        {/* <ListItemIcon>
                            <CheckIcon style={chick} />
                        </ListItemIcon> */}
                        <ListItemText primary={six} />
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    )
}
const chick = {
    color: '#fff',
    backgroundColor: '#45C655',
    fontSize: '18px',
    borderRadius: '50%',
    padding: 0.4
}
export default ListPricing
