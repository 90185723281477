import React from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Divider } from '@mui/material';
import SotialCard from './SotialCard';
import star from './../../assets/CardClass/star.svg'
import eyes from './../../assets/CardClass/eyes.svg'
import person from './../../assets/CardClass/person.svg'
const CardClass = ({ url, title, description, }) => {
    return (
        <div>
            <Card sx={{ maxWidth: '100%' }}>
                <CardMedia
                    sx={{ height: 240 }}
                    image={url}
                    title="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
                <Divider />
                <CardActions>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '100%' }} >
                        <SotialCard url={person} text={'4.2k'} />
                        <SotialCard url={eyes} text={'9.3k'} />
                        <SotialCard url={star} text={'2.0k'} />
                    </Box>
                </CardActions>
            </Card>
        </div>
    )
}

export default CardClass
